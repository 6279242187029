<template>
  <k-dialog @close="cancel" width="400" overlay="false">
    <v-form v-model="valid" ref="newCaseForm">
      <v-container style="padding: 3px">
        <v-row style="padding: 3px">
          <!-- site -->
          <v-col cols="12" style="padding: 3px" md="12">
            <v-autocomplete
              v-model="itemInEdit.filter.column"
              :items="fieldNames"
              item-text="name"
              item-value="id"
              label="Select Field"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="padding: 3px" md="12">
            <v-select
              v-model="itemInEdit.filter.operator"
              :items="operators"
              label="Operator"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="padding: 3px" md="12">
            <v-text-field
              v-model="itemInEdit.filter.value"
              label="Value"
              v-on="on"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="padding: 3px" md="12">
            <v-text-field
              v-model="itemInEdit.filter_group"
              label="Filter Group"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <dialog-actions-bar>
      <kbutton @click="cancel"> Cancel </kbutton>
      <kbutton :theme-color="'primary'" @click="save"> Save </kbutton>
    </dialog-actions-bar>
  </k-dialog>
</template>
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { NumericTextBox, Input, Checkbox } from "@progress/kendo-vue-inputs";
import { Label } from "@progress/kendo-vue-labels";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList, AutoComplete } from "@progress/kendo-vue-dropdowns";

export default {
  components: {
    "kendo-autocomplete": AutoComplete,
    dropdownlist: DropDownList,
    "k-input": Input,
    numerictextbox: NumericTextBox,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    checkbox: Checkbox,
    klabel: Label,
  },
  props: {
    dataItem: Object,
  },
  data: function () {
    return {
      itemInEdit: {
        filter: {
          column: "",
          operator: "",
          value: "",
        },
        filter_group: "",
      },
      operators: ["=", "!=", ">", "<", ">=", "<=", "LIKE"],
      fieldNames: [
        { id: "customer_name", name: "Customer Name" },
        { id: "account_name", name: "Account Name" },
        { id: "name", name: "Name" },
        { id: "email", name: "Email" },
        { id: "phone", name: "Phone" },
        { id: "monitoring_url", name: "Monitoring Url" },
        { id: "install_year", name: "Install Year" },
        { id: "inverter_manufacturer", name: "Inverter Manufacturer" },
        { id: "address", name: "Address" },
        { id: "city", name: "City" },
        { id: "state", name: "State" },
        { id: "zip", name: "Zip" },
        { id: "shade_winter", name: "Shade Winter" },
        { id: "shade_summer", name: "Shade Summer" },
        { id: "shade_fallspring", name: "Shade Fallspring" },
        { id: "monitoring_system", name: "Monitoring System" },
        { id: "inserted_at", name: "Inserted At" },
        { id: "updated_at", name: "Updated At" },
        { id: "check_frequency", name: "Check Frequency" },
        { id: "solar_module_wattage", name: "Solar Module Wattage" },
        { id: "contact", name: "Contact" },
        { id: "is_archived", name: "Is Archived" },
        { id: "is_paused", name: "Is Paused" },
        { id: "annual_production_estimate", name: "Annual Production Estimate" },
        { id: "secondary_monitoring_system", name: "Secondary Monitoring System" },
        { id: "internal_id", name: "Internal ID" },
        { id: "component_2", name: "Component 2" },
        { id: "annual_fee", name: "Annual Fee" },
        { id: "annual_visit", name: "Annual Visit" },
        { id: "exp_date", name: "Exp Date" },
        { id: "utitlity_rate", name: "Utitlity Rate" },
        { id: "performance_guarantee", name: "Performance Guarantee" },
      ],

    };
  },
  created: function () {
    if (this.$props.dataItem) {
      this.itemInEdit = this.$props.dataItem;
    }
  },
  methods: {
    onFruitChange(event) {
      this.selectedFruit = event.sender.value();
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>